.statistic-box {
  height: 120px;
  width: calc(20% - 10px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  .pl-5 {
    padding-left: 5px;
  }

  .ant-divider {
    margin: 10px 0px;
    color: rgba(255, 255, 255, 0.466);
    border-top: 1px solid rgb(243, 241, 241);
  }
  .tick {
    color: white !important;
    font-weight: 500;
    align-items: center;
    .anticon-user-add {
      margin-right: 4px;
    }
  }
  .top-i {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;

    img {
      height: 40px;
    }
  }
  .ant-statistic {
    cursor: pointer;
    .ant-statistic-title {
      font-size: 16px !important;
      color: white;
    }
    .ant-statistic-content {
      .ant-statistic-content-prefix {
        color: white;
        font-size: 20px;
      }
      .ant-statistic-content-value {
        font-size: 20px;
        color: white;
      }
    }
  }
}
.mt-10 {
  margin-top: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.custom-tooltip {
  padding: 3px 15px;
  min-width: 110px !important;
  // width: 110px !important;
  .label {
    margin-top: 5px;
    text-transform: capitalize;
  }
}
.pl-5 {
  padding-left: 5px;
}
.flex-d {
  display: flex;
  align-items: center;
  width: 100%;
}
.whitespace {
  white-space: nowrap;
  margin-right: 10px;
}
.sd-graph-row {
  margin: 10px 0px;
  flex-direction: row-reverse;
}

.sd-graph-side {
  width: 100%;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.sd-graph-side-right {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.responsive-box4-smallscreen,
.responsive-box5-smallscreen {
  display: none !important;
}

.responsive-box4-largescreen,
.responsive-box5-largescreen {
  display: block;
  padding-top: 7px;
}

.statistic-box-side {
  height: 120px;
  cursor: pointer;
  border-radius: 10px;
  width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-divider {
    margin: 10px 0px;
    color: rgba(255, 255, 255, 0.466);
    border-top: 1px solid rgb(243, 241, 241);
  }
  .tick {
    color: white !important;
    font-weight: 500;
    align-items: center;
    .anticon-user-add {
      margin-right: 4px;
    }
  }
  .top-i {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    img {
      height: 40px;
    }
  }
  .ant-statistic {
    .ant-statistic-title {
      font-size: 16px !important;
      color: white;
    }
    .ant-statistic-content {
      .ant-statistic-content-prefix {
        color: white;
        font-size: 20px;
      }
      .ant-statistic-content-value {
        font-size: 20px;
        color: white;
      }
    }
  }
}
.sd-graph {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 30px 30px 30px 0px;
  height: auto;
  .sd-graph-heading {
    font-size: 1.25rem;
    text-align: center;
    font-size: 500;
    font-weight: 600;
  }
  .ant-divider {
    margin: 0px;
    margin-bottom: 10px;
  }
}
.recharts-text {
  text-transform: capitalize;
}
.recharts-responsive-container .recharts-wrapper .recharts-surface .recharts-text .recharts-cartesian-axis-tick-value{
  margin-top: 30px !important;
}
.recharts-tooltip-wrapper {
  // border: 1px solid #37bc9b;
  background-color: white;
  box-shadow: 1px 1px 60px 1px;
}
.color-1 {
  background: #fb6800 !important;
}
.color-2 {
  background: #37bc9b;
}
.color-3 {
  background: #f6bb42; /* fallback for old browsers */
}
.color-4 {
  background: #8d4512; /* fallback for old browsers */
}
.color-5 {
  background: #3bafda; /* fallback for old browsers */
}
.color-6 {
  background: #e2477a; /* fallback for old browsers */
}
.color-7 {
  background: #dd4b39; /* fallback for old browsers */
}

@media only screen and (max-width: 1025px) {
  .statistic-box {
    width: calc(25% - 10px);
  }

  .sd-graph-side {
    width: 25%;
  }

  .sd-graph {
    width: calc(75% - 2px);
  }

  .responsive-box5-smallscreen {
    display: block !important;
    margin-bottom: 10px;
    padding-top: 16px;
  }

  .responsive-box5-largescreen {
    display: none;
  }

  .responsive-box4-largescreen {
    display: block;
  }

  .responsive-box4-smallscreen {
    display: none !important;
  }
}

@media only screen and (max-width: 800px) {
  .statistic-box {
    width: calc(34% - 10px);
  }

  .sd-graph-side {
    width: 34%;
  }

  .sd-graph {
    width: calc(66% - 2px);
  }

  .responsive-box5-smallscreen {
    display: block !important;
    margin-bottom: 10px;
    padding-top: 16px;
  }

  .responsive-box5-largescreen {
    display: none;
  }

  .responsive-box4-largescreen {
    display: none;
  }

  .responsive-box4-smallscreen {
    display: block !important;
    margin-bottom: 10px;
    padding-top: 16px;
  }
}

@media only screen and (max-width: 426px) {
  .statistic-box {
    width: 100%;
    margin-bottom: 10px;
  }

  .sd-graph-side {
    width: 100%;
    order: 1;
    margin-top: 0px;
    padding-top: 0px;
  }

  .sd-graph {
    width: 100%;
    margin-bottom: 10px;
    order: 2;
    margin-top: 10px;
  }

  .statistic-box-side {
    width: 100%;
  }

  .sd-graph-row {
    margin: 0px;
  }

  .responsive-box5-smallscreen {
    display: block !important;
    margin-bottom: 10px;
    padding-top: 16px;
  }

  .responsive-box5-largescreen {
    display: none;
  }

  .responsive-box4-largescreen {
    display: none;
  }

  .responsive-box4-smallscreen {
    display: block !important;
    margin-bottom: 10px;
    padding-top: 16px;
  }
}
