.tms-login-outer-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;

  .tms-login-left {
    width: 70%;
    height: 100%;
    // background-image: url("../../images/SD-gallery-05.png");
    background-position: right;
    background-size: cover;
    .tms-carousel {
      width: 100%;
      height: 100%;
      .tms-carousel-slide {
        position: relative;
        z-index: 1;
        .tms-carousel-inner-text {
          position: absolute;
          z-index: 2;
          bottom: 10px;
          margin-left: 50px;
          color: white;
          border-left: 2px solid white;
          padding-left: 10px;
          font-size: large;
        }
      }
    }
  }
  .otp {
    width: 50px;
    height: 60px;
    margin-right: 10px;
    border-radius: 4px;
    input {
      margin: 0px;
      height: 50px;
      width: 100% !important;
      padding: 10px;
      text-align: center;
      height: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: none;
        background-color: #eaeaea;
      }
    }
  }
  .otp:nth-child(4) {
    margin-right: 0;
  }
  .tms-login-right {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .tms-login-top {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .tms-login-logo {
        // margin-top: 20%;
        height: 80px;
        margin-bottom: 10px;
      }
      .tms-login-h2 {
        font-weight: 700;
        margin-bottom: 30px;
      }
      .tms-login-step-one {
        width: 70%;
        .tms-p-red {
          margin-top: 10px;
          width: 100%;
          color: #e11513;
          text-align: center;
          margin-bottom: 0px;
          cursor: pointer;
        }
        .tms-input {
          position: relative;
          margin-bottom: 10px;
          div {
            justify-content: center;
          }
          .tms-error-p {
            position: absolute;
            right: 0;
            color: red;
            margin-top: -10px;
          }
          .d-flex-c {
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              text-align: left;
            }
            .tms-login-button {
              background-color: #e11513;
              color: white;
              outline: none;
              border: none;
            }
            .mobile-field {
              width: 100%;
              text-align: left;
              position: relative;
              .sd-error-exclamation {
                position: absolute;
              }
              .ant-int-group {
                margin: 10px 0px !important;
              }
            }
            .tms-error-exclamation {
              position: absolute;
              top: 51%;
              right: 35px;
              color: #e11513;
            }
          }
        }
      }
    }
    .tms-reserved {
      background-image: url("../../images/tms-temple-bottom.png");
      background-position: center bottom;
      background-repeat: no-repeat;
      width: 100%;
      height: 320px;
      background-size: cover;
      position: relative;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      .tms-r-p {
        position: absolute;
        bottom: 0;
        margin-bottom: 5px;
      }
    }
  }
}
.login-two-heading {
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: -10px;
}
@keyframes from-left {
  0% {
    display: none;
    left: -10px;
  }
  100% {
    left: 50px;
  }
}
@media (max-height: 660px) {
  .tms-login-outer-wrapper {
    .tms-login-right {
      .tms-login-top {
        .tms-login-logo {
          // margin-top: 20%;
          height: 50px;
          margin-bottom: 5px;
        }
        .tms-login-h2 {
          font-weight: 700;
          font-size: 20px;
          margin-bottom: 30px;
        }
        .tms-login-step-one {
          width: 70%;
          .tms-p-red {
            margin-top: 10px;
            width: 100%;
            color: #e11513;
            text-align: center;
            margin-bottom: 0px;
            cursor: pointer;
          }
          .tms-input {
            position: relative;
            margin-bottom: 5px;
            .tms-error-p {
            }
            .d-flex-c {
              label {
                text-align: left;
              }
              .tms-login-button {
              }
              .mobile-field {
                .sd-error-exclamation {
                }
                .ant-int-group {
                  margin: 5px 0px !important;
                  .ant-input {
                    height: 30px !important;
                  }
                }
              }
              .tms-error-exclamation {
                position: absolute;
                top: 51%;
                right: 35px;
                color: #e11513;
              }
            }
          }
        }
      }
      .tms-reserved {
        height: 200px;
        background-size: cover;
        position: relative;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        .tms-r-p {
          position: absolute;
          bottom: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
}
