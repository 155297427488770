.tms-lease-container {
  .top {
    .title {
      padding: 0px 20px;

      text-align: center;
      border-bottom: 1px solid black;
      border-top: 1px solid black;
      border-top-style: dotted;

      border-bottom-style: dotted;
    }
    .header {
      padding: 0px 20px;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px 0px 20px 0px;
      .logo {
        padding-right: 40px;
        img {
          height: 90px;
          width: 90px;
        }
      }
      .qrcode {
        width: 90px;
        height: 90px;
      }
      border-bottom: 1px solid black;
      border-bottom-style: dotted;
    }
    h2 {
      padding: 0px 20px;
      text-align: center;
    }
    .data-table {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      padding: 0px 20px;

      tbody {
        width: 100%;
        tr {
          width: 100%;
          .col-1 {
            width: 45%;
            font-size: 15px;
          }
          .col-2 {
            width: 10%;
            font-size: 15px;
          }
          .col-3 {
            width: 45%;
            font-size: 15px;
          }
        }
      }
    }
    .dotted-line {
      padding: 0px 20px;

      border-bottom: 1px solid black;
      border-bottom-style: dotted;
    }
    p {
      padding: 0px 20px;

      font-size: 12px;
      text-align: center;
      padding-top: 10px;
    }
  }

  .footer {
    background-color: #fffcdc;
    font-size: 11px;
    text-align: center;
  }
}
.bg-invoice {
  background: white;
  padding: 10px 80px;

  .top {
    h2 {
      padding: 10px 0px;
    }
    .data-table {
      margin-top: 0px;
      .col-1 {
        padding: 5px 30px 5px 10px;
        background-color: #ecf0f5;
        border-bottom: 1px solid #cdd7e2;
        text-align: right;
        width: 35% !important;
      }
      .col-3 {
        padding: 5px 10px 5px 30px;
        width: 60% !important;
        border-bottom: 1px solid #cdd7e2;
        text-align: left;
        padding-left: 30px;
      }
    }
  }
  .divider {
    margin: 0px;
  }
}
.w-300 {
  width: 300px !important;
}
