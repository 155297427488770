.DetailsModal {
  .ant-modal-body {
    display: flex;
  }
}

.viewDetails {
  flex-direction: column-reverse;
  display: flex;
}

.DetailsTable {
  text-align: left;
  justify-content: center;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 5px;
  }

  tr:nth-child(odd) {
    background-color: #dddddd;
  }
}

.ImgCol {
  width: 75%;
  text-align: center;
  justify-content: center;
  margin: auto;

  img {
    width: 60%;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.TermsDrawer {
  .ant-drawer-header {
    button.ant-drawer-close {
      display: none;
    }
  }
}
