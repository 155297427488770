.VehicleTypeDiv {
  .ant-card-body {
    justify-content: space-between;
    display: flex;
    width: 100%;
    align-items: center;
  }
  .ant-col {
    display: flex;
  }
  p {
    margin: 0;
    font-weight: bold;
  }
}
