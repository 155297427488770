.tms-d-row-one {
  display: flex;
  width: 100%;
  justify-content: space-between !important;
  .tms-dashboard-heading {
    font-size: 1.25rem;
    font-weight: 600;
  }
}
.tms-d-row-two {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: space-between;
  font-family: "Zen Kaku Gothic New", sans-serif;
  .id-elements {
    width: 16%;
    height: 125px;
    cursor: pointer;
    margin-bottom: 12px;
    box-shadow: 2px 4px 10px #0000000f;
    border-radius: 9px;
    border-radius: 9px;
    opacity: 1;
    // margin: 0px;
    .ant-card-body {
      padding: 12px 12px 0px 12px !important;
      text-align: left;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      .id-elements-top {
        height: fit-content;
        display: flex;
        justify-content: space-between;
        p {
          margin-bottom: 0px;
          font-size: 12px;
          color: white;
        }
        .bottom {
          display: flex;
          align-items: center;
          img {
            bottom: 10px;
            right: 10px;
            width: 40px;
            max-height: 48px;
          }
        }
      }
      .id-elements-bottom {
        margin: 20px 0px 0px 0px;
        height: fit-content;
        border-top: 1px solid #fff;
        padding-top: 7px;
        .top {
          font-size: 12px;
          color: white;
          display: flex;
          justify-content: space-between;
        }
        .bottom {
          color: white;
        }
      }
    }
  }
  .tms-d-divider {
    margin: 20px 0px 0px 0px;
    color: white;
    background-color: white;
    height: 0.5px;
  }
  .id-80 {
    height: 80%;
  }
  .id-100 {
    height: 100%;
  }
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.br-25 {
  border-radius: 0.25rem !important;

  .ant-select-selector {
    border-radius: 0.25rem !important;
  }
}
