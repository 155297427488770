.tms-welcome {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tms-layout-bc {
  text-align: left;
  padding: 5px;
  z-index: 1000;
  margin-top: 1px;
  margin-bottom: 0px;
  height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 2px 15px #0000001a;
  ol {
    margin-bottom: 0px;
    margin-left: 10px !important;
    height: 40px !important;
    padding-left: 10px !important;
    display: flex;
    align-items: center;
  }
  .ant-breadcrumb {
    background-color: #ffffff !important;
    margin-left: 10px !important;
    padding-left: 10px !important;
    margin-bottom: 0px;
    ol {
      margin-bottom: 0px;
    }
  }
}
.tms-layout-content {
  // margin: 14px 16px;
  padding: 24px;
  overflow: auto;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tms-footer {
  z-index: 1000;
  height: 40px;
  padding: 5px 25px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 5px #0000000f;

  p {
    margin-bottom: 0px;
    align-self: center;
  }
}
.menu_sider::-webkit-scrollbar {
  display: none;
}

.menu_sider {
  padding-bottom: 150px !important;
  height: 100%;
  overflow-y: scroll !important;
}
.ant-menu:last-child {
  padding-bottom: 70px;
}
.tms-sider-logo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 54px !important;
  margin-top: 6.3px !important;
  .tms-sider-inner-logo {
    height: 40px;
    width: 45px;
    border-radius: 50%;
  }
}
.tms-menu_sider {
  position: fixed;
  left: 0;
  z-index: 1000;
  height: 100vh;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #632601;
}
.ant-layout-sider-children {
  overflow-y: scroll;
  overflow-x: hidden;
}
.ant-layout-sider-children::-webkit-scrollbar {
  display: none;
}
.ant-menu,
.ant-menu-root {
  outline: none;
  box-shadow: none;
  border: none;
}
