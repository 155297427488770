.tms_trigger_class {
  z-index: 10 !important;
  top: 25px !important;
  display: flex;
  float: right;
  margin-right: 25px;
  position: absolute;
}
.tms-shift-in-wrapper {
  top: 0;
  right: 0;
  display: flex;
  float: right;
  margin-right: 25px;
  position: absolute;
}
.reportPop {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ant-layout-sider-zero-width-trigger,
.ant-layout-sider-zero-width-trigger-left {
  display: none !important;
}
