.parakamani {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  .parakamaniChild {
    max-width: 1300px;
    width: 100%;
    background: white;
    padding: 30px 50px;
    text-align: left;
  }
  .ant-input[disabled] {
    background: #ffffff;
    color: black;
  }
  .ant-table-wrapper {
    margin-top: 10px;
  }
  .buttons {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .totalAmount {
    background: #fffcdc;
    border: 1px solid #eef2f6;
    border-top: 0;
    padding: 10px 20px;
    justify-content: end;
    width: 100%;
  }
  .ant-upload-list-item-actions {
    text-align: center;
  }
  .uploadBox {
    width: 100%;
    flex-direction: column;
    background-color: #eef2f6;
    border-radius: 10px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
    .ant-upload-picture-card-wrapper {
      left: 20px;
      z-index: 5;
      .ant-upload-list-item {
        background-color: white;
      }
    }
    .ant-upload-drag-icon {
      color: #40a9ff;
      font-size: 48px;
      margin-bottom: 0px;
      opacity: 50%;
    }
    .ant-upload-text {
      margin: 0 0 4px;
      color: #000000d9;
      font-size: 16px;
      opacity: 50%;
    }
  }
}
