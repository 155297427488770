body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.f-13 {
  font-size: 13px !important;
}
.f-12 {
  font-size: 12.5px !important;
}
.mt-20 {
  margin-top: 20px;
}
.ant-upload-span {
  text-align: left;
}
.ant-switch-checked {
  background: #4cbb17 !important;
}

.ant-switch {
  background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0.25)
    ),
    linear-gradient(90deg, #fff, #fff);
}
