.roomsTable {
  .ant-table-content {
    overflow-x: hidden !important;
  }
  th,
  tr {
    text-align: center !important;
  }
}

.uploadDoc {
  .ant-upload {
    width: 100%;
  }
}

.CountryRegionDropDown {
  border: 1px solid #d9d9d9;
  height: 32px;
  font-size: 14px;
  padding: 0px 8px;
  width: 100%;
  border-radius: 2px;
}
.c-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.RoomCard {
  .ant-card-body {
    padding: 0 !important;
  }
}

.fingerprint {
  font-size: 20px !important;
  font-weight: bold;
  border: 1px solid rgb(217, 217, 217) !important;
  height: 140px;
  cursor: pointer !important;
  text-align: center;
  .ant-card-body {
    height: 140px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
