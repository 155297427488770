.card-container {
  height: auto;
  width: 100%;
  max-width: 100%;
  max-height: auto;
  overflow: hidden;

  overflow-x: hidden;
}
.ant-tabs-nav-operations {
  height: 30px;
}
.ant-tabs-tab {
  clip-path: polygon(0% 0%, 82% 0, 100% 101%, 100% 100%, 0% 100%);
  padding-right: 30px !important;
  border-bottom: none !important;
  margin-left: 0px !important;
  border-top-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
  height: 30px !important;
  min-width: 230px;
}
.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: auto;
  max-height: auto;
  overflow: hidden;
  // margin-top: -16px;
  margin-left: 1px;
  &::-webkit-scrollbar {
    display: none;
  }
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
  height: 100%;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme="dark"] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme="dark"]
  .card-container
  > .ant-tabs-card
  .ant-tabs-content
  > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}
.card-container {
  .ant-tabs {
    height: 100% !important;
    max-height: 100% !important;
    overflow: hidden;

    .ant-tabs-nav {
      margin-bottom: 0px;
    }
    .ant-tabs-content-holder {
      height: 100%;
      max-height: 100%;
      overflow: hidden;

      .ant-tabs-content {
        height: 100% !important;
        max-height: 100% !important;
        overflow-y: scroll;
      }
    }
  }
}
.ant-tabs-nav-wrap {
  max-width: 100%;
  max-height: 100% !important;
  overflow: hidden;
}
.ant-tabs-tab-btn {
  padding-top: 0px !important;
}
.ant-tabs-top {
  overflow: hidden;
}

.tms-tabs-inner-scroll {
  .ant-tabs-content-holder {
    height: auto !important;
  }
}
