.labelStyles {
  text-align: left;
  font-size: 17px;
  margin-bottom: 5px;
}

.amountStyles {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
}

.amountPaidStyle {
  width: 70px !important;
  text-align: left;
  border: none !important;
  border-bottom: 1px black solid !important;
  font-size: 23px !important;
  font-weight: 600;
  padding: 0px !important;
  margin-left: 5px !important;
}

.amountLabelStyles {
  font-size: 24px;
}

.itemCard {
  border: 0 !important;
  border-bottom: 1px solid #edf2f9 !important;
  .ant-card-body {
    padding: 0px !important;
  }
}

.performerModal {
  .ant-card-body {
    padding: 10px 20px !important;
  }
}
