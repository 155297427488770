.w-full {
  width: 100% !important;
}

.w-4 {
  width: 25%;
}

.w-3 {
  width: 33.33%;
}

.w-2 {
  width: 50%;
}

.h-32 {
  height: 32px;
}

.flex {
  display: flex;
}

.col {
  flex-direction: column;
}

.bg-white {
  background: white;
}

.text-left {
  text-align: left;
}

.pxy {
  padding: 30px 50px;
}

.p-0 {
  padding: 0;
}

.maxw-1300 {
  max-width: 1300px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-15 {
  margin-right: 15px;
}

.m-0 {
  margin: 0 !important;
}

.px-10 {
  padding: 0px 10px;
}

.p-10 {
  padding: 10px;
}

.mandatory {
  color: red;
  margin-left: 3px;
}

.justify-between {
  justify-content: space-between;
}

.text-lg {
  font-size: 24px;
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.text-desc {
  color: #707070;
}

.text-bold {
  font-weight: bold;
}

.text-med {
  font-weight: 500;
}

.card {
  border: 1px solid #707070;
  border-radius: 10px;
  .ant-checkbox {
    scale: 1.5;
  }
}

.buttonDiv {
  .add {
    span {
      scale: 2.5;
      padding-bottom: 2px;
    }
  }
}

.sd-EM {
  position: absolute;
  bottom: -24px;
  font-size: 14px;
  right: 0;
  text-align: right;
  margin-bottom: 0;
  color: red;
}

.rotate-45 {
  transform: rotate(45deg);
}

.pointer {
  cursor: pointer;
  color: #1890ff;
}

.level {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  width: 250px;
}

.sub-table {
  .ant-table-content {
    width: max-content;
  }
}
.protocolDashBoard {
  table {
    width: max-content !important;
  }
}
