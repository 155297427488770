.background {
    position: absolute;
    opacity: 0.3;
    height: 100px;
  }
  .sd-display-cards {
    .sd-display-card {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      border-radius: 4px;
  
      .ant-card-body {
        flex-direction: column;
        margin: auto;
        padding: 0;
        margin-left: 15px;
        .ant-card-meta {
          display: flex;
          justify-content: flex-start;
        }
        width: 60%;
      }
      .ant-card-actions {
        width: 40%;
        display: flex;
        align-items: center;
        border-top: none;
        li {
          span {
            .anticon {
            }
          }
        }
      }
    }
  }
  .anticon-minus {
    svg {
      color: red;
    }
  }
  .anticon-plus {
    svg {
      color: black;
    }
  }
  .right {
    left: 50%;
    top: 40%;
  }
  
  // .ticket-card .ant-card-body {
  //   padding: 0 !important;
  // }
  
  // .ticket-card-col {
  //   padding: 24px;
  // }
  .tickettitle {
    justify-content: center;
    margin: auto;
    font-weight: bold;
    text-align: center;
  }
  
  .tickettitle p {
    margin-bottom: 0;
  }
  
  .ticket-one {
    padding-right: 10px;
    border-right-style: dashed;
    float: left;
    width: 50%;
  }
  
  .ticket-two {
    padding-left: 10px;
    float: right;
    width: 50%;
  }
  
  .ticket_label {
    width: 40%;
    font-weight: bold;
    text-transform: capitalize;
  }
  
  .seva_ticket_col {
    display: inline-flex;
    .ticket_colon {
      padding-right: 10px;
    }
  }
  
  .amount-label {
    font-weight: normal;
  }
  
  .ticket_data {
    width: 60%;
    overflow-wrap: break-word;
    text-transform: capitalize;
  }
  
  .ticket_data p,
  .ticket_label p {
    margin-bottom: 0;
  }
  
  .footerrow {
    justify-content: center !important;
    margin: auto !important;
    width: 100% !important;
    text-align: center;
    font-size: 10px;
  }
  
  .ant-divider-dashed {
    border-color: black !important;
    border-width: 2px 0 0 !important;
    margin: 10px !important;
  }
  
  .footerrow p {
    margin-bottom: 0 !important;
  }
  
  .hideLogo {
    display: none;
    // visibility: hidden;
  }
  
  .displayLogo {
    display: block;
  }
  .displayL {
    height: 100px;
    visibility: hidden;
  }
  .hideL {
    display: none;
  }
  @media print {
    .displayL {
      height: 150px;
      visibility: hidden;
    }
    .hideL {
      display: none;
    }
    .sd-ticket-container {
      margin-top: 0px;
      // width: 320px;
      // height: 600px;
      text-align: center;
    }
  
    .sd-bond-ticket-container {
      margin-top: 190px;
      width: 100%;
      text-align: center;
    }
  
    .sd-bond-ticket-container .ticket_label {
      visibility: hidden;
    }
  
    .displayLogo {
      display: block;
    }
  
    .hideLogo {
      display: none;
    }
  
    .sevaTicketTitle {
      padding-bottom: 150px;
      visibility: hidden;
    }
  }
  
  #interactive.viewport {
    width: 400px;
    height: 300px;
  }
  
  #interactive.viewport canvas,
  video {
    width: 400px;
    height: 300px;
    position: absolute;
    top: 40px;
    left: 0;
  }
  
  #interactive.viewport canvas.drawingBuffer,
  video.drawingBuffer {
    width: 400px;
    height: 300px;
  }
  