.App {
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.select-heading {
  float: left !important;
  font-size: 17px !important;
  margin-bottom: 5px !important;
}
.capture-wrapper {
  display: flex;
  flex-direction: column;
}
.camera-capture {
  width: 400px;
  position: initial !important;
}
.cursor {
  cursor: pointer;
}
.grecaptcha-badge {
  display: none !important;
}
.tms-EM {
  color: red;
}
.sd-pop-up {
  position: fixed;
  border: none !important;
  outline: none !important;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 49;
  box-shadow: none !important;
  background: rgba(0, 0, 0, 0.8);

  .inside-pop {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    // max-height: 40vh;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 50px 1px #000;
    padding: 25px;
    position: relative;
    animation: scale 1s;
    text-align: center;
    font-size: 20px;

    .inside-slider {
      width: 100%;
      height: 300px;
      .slick-track {
        width: 100% !important;
      }
    }
    .sd-flex-c {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
      .s-button {
        background-color: #991403;
        color: white;
        padding: 5px 10px;
        font-size: 15px;
        border: none;
        border-radius: 4px;
        display: flex;
        align-items: center;
        .s-l-left {
          margin-left: 5px;
          height: 11px;
          margin-top: 2px;
        }
        .s-r-right {
          margin-left: 5px;
          height: 11px;
          margin-top: 2px;
        }
      }
    }
    .s-image {
      width: 100%;
    }
    .sd-top-icon {
      height: 100px;
      margin-bottom: 10px;
    }
    .sd-none {
      display: none;
    }
    .display {
      position: absolute;
      top: 0px;
      right: 10px;
      font-weight: 500;
      border: none;
      height: 8px;
      font-size: 18px;
      background-color: transparent;
    }
    .sd-down-icon {
      height: 150px;
    }
    .sd-top-mv {
      padding: 0px 25px;
      margin-top: -20px;
    }
    p {
      color: #181818;
      font-size: 500;
      padding: 0px 20px;
      line-height: 26px;

      span {
        font-weight: 600;
      }
    }
  }

  .in-scroll {
    height: 500px !important;
    overflow-x: scroll;
    width: auto;
    // width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.f-24 {
  font-size: 24px !important ;
}
.relative {
  position: relative;
}
.f-16 {
  font-size: 16px;
}
.pr-0 {
  padding-right: 0px;
}
.p-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.m-0 {
  margin: 0;
}
.w-100 {
  width: 100%;
}
.p-0 {
  padding: 0 !important;
}
.mr-20 {
  margin-right: 20px;
}
.mb-0 {
  margin-bottom: 0;
}
.boldText {
  font-weight: 600;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.align-center {
  align-items: center;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.loading-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading-logo img {
  height: 70px;
  mix-blend-mode: darken;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
