.itemCard {
  border: 0 !important;
  border-bottom: 1px solid #edf2f9 !important;
  // margin-top: 30px;
  // overflow-y: scroll;
  // &::-webkit-scrollbar {
  //   // display: none;
  //   width: 18px;
  // }
  &::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
    background-color: #cfcfcf;
  }
  .ant-card-body {
    padding: 0px !important;
  }
}
