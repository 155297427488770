.parent {
  align-items: center;
  flex-direction: column !important;

  .w-full {
    width: 100%;
  }

  .w-10 {
    width: 10%;
  }

  .w-30 {
    width: 30%;
  }

  .w-4 {
    width: 25%;
  }

  .w-3 {
    width: 33%;
  }

  .w-2 {
    width: 50%;
  }

  .w-60 {
    width: 60%;
  }

  .w-40 {
    width: 40%;
  }

  .max-width {
    max-width: 1300px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .card {
    background-color: white;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 20px;
    border: white;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-start {
    justify-content: flex-start;
  }

  .border-r-2 {
    border-radius: 2px;
  }

  .button-w {
    width: 96px;
  }

  .p-10 {
    padding: 0px 11px;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .background-white {
    background-color: white;
  }

  .pxy {
    padding: 30px 50px;
  }

  .pb-10 {
    padding-bottom: 10px;
  }

  .m-5 {
    margin: 5px 0px;
  }

  .labelStyles {
    float: left;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .mandatory {
    color: red;
    margin-left: 3px;
  }

  .headings {
    font-size: 24px;
    color: #333333;
    font-weight: 600;
    padding: 20px 0px;
  }

  .subHeadings {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
  }

  .subData {
    font-size: 14px;
    color: #333333;
    text-align: left;
    margin-bottom: 10px;
  }

  .scale-2 {
    transform: scale(1.5);
  }

  .align-center {
    align-items: center;
  }

  .align-end {
    align-items: flex-end;
  }

  .align-start {
    align-items: flex-start;
  }

  .tmsCheckboxCss {
    text-align: left;
    display: flex;
    .ant-input {
      width: 40px;
    }
    .ant-checkbox-wrapper {
      align-items: center;
      margin-right: 5px;
      width: auto;
      .ant-checkbox {
        transform: scale(1.5);
        margin-right: 8px;
      }
    }
  }

  .buttonDiv {
    text-align: left;
    .add {
      span {
        scale: 2.5;
        padding-bottom: 5px;
      }
    }
    .rotate-45 {
      transform: rotate(45deg);
    }
  }

  .ant-input-group-addon {
    padding: 0px;
    .pointer {
      padding: 0px 10px;
    }
  }

  // .divider {
  //   .ant-divider:last-child {
  //     display: none;
  //   }
  // }
}
