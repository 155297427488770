.gallery {
  .ant-modal-body {
    // height: 700px;
    overflow: hidden;
  }
  .galleryCol {
    width: 23%;
    // border-radius: 10px;
    // border: 1px solid #858585;
    margin: 3px;
    overflow: hidden;
    justify-content: center;
    display: flex;
    flex-direction: column;
    // background: #f0f2f5;
    background: center/cover no-repeat;
    cursor: pointer;
    height: 100px;

    &:hover {
      // filter: brightness(0.7);
      filter: drop-shadow(0px 0px 6px #666666);
    }
  }

  .activeBorder {
    // filter: brightness(0.7) !important;
    filter: drop-shadow(0px 0px 6px #666666);
  }
}

.hundiReport {
  .ant-modal-content {
    width: 1200px;
  }
}
