.tms-outer-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  .tms-left {
    width: 70%;
    height: 100%;
    // background-image: url("../../images/SD-gallery-05.png");
    background-position: right;
    background-size: cover;
    .tms-carousel {
      width: 100%;
      height: 100%;
      .tms-carousel-slide {
        position: relative;
        z-index: 1;
        .tms-carousel-inner-text {
          position: absolute;
          z-index: 2;
          bottom: 10px;
          margin-left: 50px;
          color: white;
          border-left: 2px solid white;
          padding-left: 10px;
          font-size: large;
        }
      }
    }
  }
  .tms-right {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tms-logo {
      margin-top: 20%;
      height: 80px;
      margin-bottom: 10px;
    }
    .tms-h2 {
      font-weight: 700;
      margin-bottom: 30px;
    }
    .tms-back-arrow-text {
      color: blue;
    }
    .tms-reserved {
      position: absolute;
      bottom: 10px;
    }
  }
}
.lease-parent {
  display: grid;
  width: 100%;
  height: 100%;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  cursor: pointer;
  .lease-div {
    padding: 55px 70px;
    box-shadow: 2px 4px 10px #00000029;
    cursor: pointer;
    opacity: 1;
    img {
      margin-bottom: 10px;
    }
    h2 {
      font: normal normal bold 25px;
      margin-bottom: 10px;
    }
    .creation {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        padding-left: 2px;
        margin-bottom: 0px;
      }
    }
    .c-black {
      color: #131313 !important;
    }
    .c-white {
      color: white !important;
    }
  }
}
.creation-main {
  display: flex;
  align-items: center;
  h2 {
    margin-bottom: 0px;
    margin-right: 10px;
    font-size: 18px;
    color: #333333 !important;
    &:last-child {
      margin-left: 10px;
    }
  }
}
.t-left {
  text-align: left;
  width: 100%;
  margin-bottom: 3px;
}
.bg-grey {
  background: #dcdcdc !important;
  border-radius: 2px;
  opacity: 1;
  .ant-input {
    background: #dcdcdc !important;
  }
}
.suffix-border {
  border-left: 1px solid grey;
  padding-left: 10px;
}
